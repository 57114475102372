* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
.justify-content-end {
  display: flex;
}
#root {
  background-color: $color-white;
}
.page-link {
  color: $color-primary !important;
  background-color: white !important;
}
.active > .page-link {
  background-color: $color-primary !important;
  border-color: $color-primary !important;
  color: white !important;
}
body {
  @include mobile-l {
    font-size: 10px;
  }
}
