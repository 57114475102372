//Colors
$color-primary: #720000;
$color-primary-light: #f5cac2;
$color-primary-dark: #f32f11;
$color-secondary: #303179;
$color-secondary-light: #5759c2;
$color-secondary-dark: #141850;
$color-white: #fff;
$color-black: #000;
$color-grey: #767676;
$color-grey-light: #dedede;
$color-grey-dark: #3e3e3e;

$theme-colors: (
  "primary": #720000,
);
