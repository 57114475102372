.mentor {
  padding-top: 2em;
  padding-bottom: 2em;
  &--card {
    text-align: justify;
    flex-direction: row;
    align-self: stretch;
    height: 100%;
    @include mobile-l {
      flex-direction: column;
    }
  }
  &--image {
    min-width: 20%;
    max-width: 30%;
    width: 20%;
    object-fit: contain;
    @include mobile-l {
      width: 100%;
      max-width: 100%;
    }
  }
}
