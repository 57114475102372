.card {
  &__member {
    margin-bottom: 2em;
    &--image {
      border-radius: 100%;
      border: 2px solid $color-grey-dark;
      padding: 0.4em;
      width: 100%;
    }
    &--text {
      text-align: left;
    }
    &--name {
      font-size: 2em;
      font-weight: bold;
      margin: 0;
    }
    &--title {
      font-weight: 500;
      margin: 0;
    }
    &--info {
      text-align: justify;
      margin: 0;
    }
  }
}
