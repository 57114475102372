.heading--primary {
  // padding-bottom: 1em;
  font-weight: bolder;
  color: #720000;
  //
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  /* border-bottom: 2px solid rgba(4, 175, 212, 1); */
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    padding-top: 10px;
    border-bottom: 4px solid #720000;
    /* This creates the border. Replace black with whatever color you want. */
    margin-bottom: 20px;
    transition: all ease 1s;
  }
  &:hover {
    transform: translateY(-2px);
    &:after {
      width: 20%;
    }
  }
}
.heading--secondary {
  font-weight: bolder;
  color: #720000;
  text-align: left;
}
.heading {
  margin: 0;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  /* border-bottom: 2px solid rgba(4, 175, 212, 1); */
}

.heading:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  padding-top: 10px;
  border-bottom: 4px solid rgba(4, 175, 212, 1);
  /* This creates the border. Replace black with whatever color you want. */
  margin-bottom: 20px;
}

.text-justify {
  text-align: justify;
}
