.list {
  &__recent {
    padding-top: 1em;
    padding-bottom: 1em;
    &--card {
      text-align: left;
      align-items: stretch;
    }
    &--content {
      text-align: justify;
    }
    &--image {
      margin-bottom: 1em;
      margin-right: 1em;
      width: 40%;
      float: left;
      @include mobile-l {
        width: 100%;
      }
    }
  }
}
