.header {
  &--fixed {
    position: sticky;
    top: 0;
    z-index: 10;
    @include mobile-l {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
  &--top {
    background-color: $color-primary;
    padding: 0.4em;
  }
  &--links {
    width: 100%;
  }
  &--link {
    &--apply {
      margin-left: 1em;
      background-color: $color-white;
      color: $color-primary !important;
      border-radius: 0.6em;
      padding-left: 1em !important;
      padding-right: 1em !important;
      // text-transform: uppercase;
      &--start {
        margin-left: auto;
        @include mobile-l {
        margin-left: 1em;
        margin-bottom: 0.4em;
      }
      }
    }
  }
  &--link,
  &--link > a {
    font-weight: 500;
    color: $color-white;
    border-bottom: 1px solid $color-primary;
    &:hover {
      font-weight: bold;
      color: $color-white;
      border-bottom: 1px solid $color-white;
    }
  }
  &--font {
    &--group {
      display: flex;
      flex-direction: row;
      margin-left: 1em;
      gap: 0.6em;
    }
    &--btn {
      outline: none;
      border: none;
      background-color: $color-primary;
      font-weight: bolder;
      color: $color-white;
    }
  }
  &--brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30vw;
    word-wrap: break-word;
    text-align: left;
    text-decoration: none;
    font-weight: bolder;
    font-size: 0.9em;
    line-height: 1.2em;
    color: $color-primary;
    @include mobile-l {
      font-size: 0.6em;
    }
  }
  &--logo {
    width: 20%;
    padding-right: 0.6em;
    object-fit: contain;
    @include mobile-l {
      width: 30%;
    }
    &--one,
    &--two,
    &--three {
      object-fit: contain;
      @include mobile-l {
        display: none;
      }
    }
    &--one {
      max-width: 4vw;
    }
    &--two {
      max-width: 5vw;
    }
    &--three {
      padding-left: 2px;
      border-left: 1px solid $color-primary;
      max-width: 15vw;
    }
    &--text {
      font-size: 0.8em;
      line-height: 1em;
      font-weight: bold;
      margin: 0;
      @include mobile-l {
        display: none;
      }
    }
  }
}
