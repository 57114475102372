.about {
  &__members {
    padding-top: 2em;
    padding-bottom: 2em;
  }
  &__message {
    padding-top: 2em;
    padding-bottom: 2em;
    &--image {
      width: 100%;
      border-radius: 1em;
    }
    &--content {
      text-align: justify;
    }
  }
  &__story {
    padding-top: 2em;
    padding-bottom: 2em;
    // background-color: $color-grey-light;
    color: $color-grey-dark;
    &--content {
      font-size: 1.2em;
      font-weight: 500;
      text-align: justify;
    }
    &--image {
      float: left;
      width: 34%;
      margin-right: 1em;
      @include mobile-l {
        width: 100%;
        margin: 0;
        margin-bottom: 1em;
      }
    }
    &--stats {
      // margin-top: 2em;
      margin-bottom: 2em;
      background-color: $color-grey-light;
      border-radius: 1em;
      &--div {
        padding: 1em;
        border-left: 1px solid $color-grey-light;
        border-right: 1px solid $color-grey-light;
      }
      &--number {
        font-size: 4em;
        font-weight: bolder;
      }
      &--title {
        font-size: 1.5em;
      }
    }
  }
}
