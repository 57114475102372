.loader--container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.loader--logo {
  width: 4em; /* Adjust the logo size */
  height: auto;
  animation: flip 2s infinite;
  object-fit: contain;
}

@keyframes flip {
  0% {
    transform: perspective(800px) rotateY(0);
  }
  25% {
    transform: perspective(800px) rotateY(180deg);
  }
  50% {
    transform: perspective(800px) rotateY(0);
  }
  100% {
    transform: perspective(800px) rotateY(0) scale(4);
  }
}
