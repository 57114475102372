.carousel {
  &__main {
    // min-height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100vw;
  }
  &__testimonial {
    &-content {
      padding: 2em;
      background-color: #f5f5f5;
      border-radius: 5px;
      text-align: center;
      min-height: 35vh;
      height: auto;
    }
    &-text {
      font-size: 16px;
      font-style: italic;
      margin-bottom: 10px;
    }
    &-author {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &-position {
      font-size: 14px;
    }
  }
}
