.footer {
  background-image: url("../../../public/assets/images/bg_footer.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  &--div {
    background-color: rgba(256, 256, 256, 0.8);
    padding-top: 2em;
  }
  &--container {
    padding-top: 2em;
  }
  &--brand {
    margin-top: 0.4em;
    word-wrap: break-word;
    text-align: left;
    text-decoration: none;
    color: $color-primary;
    font-weight: bolder;
    font-size: 0.8em;
  }
  &--logo {
    width: 20%;
    // margin: auto;
    float: left;
    margin-right: 0.4em;
  }
  &--link {
    display: block;
    text-align: left;
    text-decoration: none;
    color: #000;
    &--title {
      display: block;
      text-align: left;
      text-decoration: none;
      color: #000;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &--info {
    display: block;
    text-align: left;
  }
  &--social {
    display: block;
    display: flex;
    flex-direction: row;
    padding-left: 20%;
    // justify-content: center;
    gap: 0.4em;
    &--icon {
      color: black;
      &:hover {
        transform: translateY(-1px);
      }
    }
  }
  &--copyrights {
    margin-top: 1em;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
