.startup {
  padding-top: 2em;
  padding-bottom: 2em;
  &--card {
    width: 100%;
    height: 16em;
    // height: 350px;
    perspective: 1000px;
    overflow: hidden;
    &:hover &--content {
      transform: rotateY(180deg);
    }
    &--image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &--content {
      width: 100%;
      height: 100%;
      transition: transform 0.5s;
      transform-style: preserve-3d;
    }
    &--body {
      width: 100%;
      height: 16em;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
      transform: rotateY(180deg);
      // display: flex;
      // flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #f8f9fa;
      color: #333;
      padding: 1em;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      overflow-y: scroll;
      // z-index: 1;
      text-overflow: ellipsis;
      white-space: normal;
      /* Hide scrollbar for webkit-based browsers */
      &::-webkit-scrollbar {
        /* Adjust the width as needed */
        // width: 0.5em;
        width: 0.1em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888; /* Adjust the color as needed */
        border-radius: 0.25em; /* Adjust the border-radius as needed */
      }
      /* Hide scrollbar for Firefox and other browsers */
      & {
        scrollbar-width: none;
      }
      /* Optionally, add some custom styles for the scrollbar in Firefox */
      & {
        scrollbar-color: #888 transparent; /* Adjust the color as needed */
      }
    }
  }
}
