.home {
  &__impact {
    padding-top: 2em;
    padding-bottom: 2em;
    background: url("../../../public/assets/images/bg_impact.jpeg") no-repeat
      center center fixed;
    background-size: cover;
    &--stats {
      // margin-top: 2em;
      padding-top: 1em;
      background-color: #ffffffcc;
      border-radius: 1em;
      @include mobile-l {
        margin: 1em;
        padding: 1em;
      }
      &--number {
        font-size: 4em;
        line-height: 1em;
        margin: 0;
        font-weight: bolder;
      }
      &--title {
        font-size: 1.5em;
        // margin: 0;
      }
    }
  }
  &__story {
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: $color-grey-light;
    color: $color-grey-dark;
    &--content {
      font-size: 1.2em;
      font-weight: 500;
      text-align: justify;
    }
    &--image {
      float: left;
      width: 34%;
      margin-right: 1em;
    }
  }
  &__testimonials {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: $color-grey-light;
    color: $color-grey-dark;
    &--content {
      font-size: 1.2em;
      font-style: italic;
    }
    &--author {
      font-size: 1.3em;
      font-style: italic;
      font-weight: 500;
    }
  }
  &__logos {
    padding-top: 2em;
    padding-bottom: 2em;
    &--image {
      width: 100%;
      border-radius: 1em;
      padding: 0.3em;
    }
  }
  &__social-media {
    padding-top: 2em;
    padding-bottom: 2em;
    &--image {
      width: 100%;
      border-radius: 1em;
      padding: 0.3em;
    }
    &--main {
      display: flex;
      flex-direction: row;
      & iframe {
        display: inline;
      }
    }
    &--scroll {
      max-height: 500px;
      overflow-y: scroll;
      min-height: 500px;
      padding: 0;
      @include mobile-l {
        max-width: fit-content;
        margin: 0 1em;
      }
      /* Hide scrollbar for webkit-based browsers */
      &::-webkit-scrollbar {
        /* Adjust the width as needed */
        // width: 0.5em;
        width: 0.1em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888; /* Adjust the color as needed */
        border-radius: 0.25em; /* Adjust the border-radius as needed */
      }
      /* Hide scrollbar for Firefox and other browsers */
      & {
        scrollbar-width: none;
      }
      /* Optionally, add some custom styles for the scrollbar in Firefox */
      & {
        scrollbar-color: #888 transparent; /* Adjust the color as needed */
      }
      &--card {
        margin-bottom: 1em;
        padding: 1em;
        border: 1px solid #dedede;
        border-radius: 1em;
        background-color: #dedede;
      }
      &--date {
        text-align: left;
        // padding: 0;
        margin: 0;
        font-weight: bold;
      }
      &--content {
        text-align: left;
        // padding: 0;
        margin: 0;
      }
    }
  }
  &__subscribe {
    padding: 2em;
    border-radius: 1em;
    background-color: #dededede;
    color: $color-grey-dark;
    @include mobile-l {
      margin: 0 1em;
      padding: 1em;
      width: auto;
    }
    &--content {
      font-size: 1.2em;
    }
    &--main {
      display: flex;
      flex-direction: row;
      gap: 1em;
      width: 40%;
      margin: 0 auto;
      @include mobile-l {
        width: auto;
      }
    }
    &--input {
      padding: 0.4em;
      border-radius: 0.5em;
      outline: none;
      flex: 1;
    }
    &--button {
      padding: 0.4em 1em;
      border-radius: 0.5em;
      outline: none;
    }
  }
  &__upcoming {
    // padding-top: 1em;
    padding-bottom: 1em;
    &--card {
      text-align: left;
      align-items: stretch;
    }
    &--main {
      @include mobile-l {
        gap: 0.5em;
      }
    }
    &--title {
      color: $color-primary;
      font-weight: bold;
    }
    &--content {
      text-align: justify;
      &--hide {
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
