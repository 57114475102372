@mixin laptop {
  @media all and (max-width: 1024px) {
    @content;
  }
}
@mixin laptop-m {
  @media all and (max-width: 991px) {
    @content;
  }
}
@mixin laptop-s {
  @media all and (max-width: 840px) {
    @content;
  }
}

@mixin tablet {
  @media all and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-s {
  @media all and (max-width: 600px) {
    @content;
  }
}

@mixin mobile-l {
  @media all and (max-width: 425px) {
    @content;
  }
}

@mixin mobile {
  @media all and (max-width: 375px) {
    @content;
  }
}

@mixin mobile-s {
  @media all and (max-width: 320px) {
    @content;
  }
}
