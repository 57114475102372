.apply {
  padding-top: 2em;
  // padding-bottom: 2em;
  &--info {
    // width: 80%;
    text-align: left;
    margin: 0 auto;
    & p {
      margin: 0;
    }
  }
  &--form {
    text-align: left;
    &--label {
      text-align: left;
      &--required:after {
        content: "*";
        color: red;
      }
    }
    &--group {
      margin-bottom: 1em;
    }
  }
  &--section {
    &--heading {
      text-align: left;
    }
  }
}
