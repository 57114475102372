.program {
  padding-top: 2em;
  padding-bottom: 2em;
  &--content {
    text-align: justify;
  }
  &--list {
    list-style: none;
    display: grid;
    gap: 1em;
    padding: 0;
    &-1 {
      grid-template-columns: repeat(7, 1fr);
      @include mobile-l {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &-2 {
      grid-template-columns: repeat(6, 1fr);
      @include mobile-l {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    & li {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
  &--image {
    width: 100%;
    margin-bottom: 1em;
    &--div {
      width: 30%;
      float: right;
      margin-left: 1em;
      @include mobile-l {
        width: 100%;
        margin-left: 0;
        margin-bottom: 1em;
      }
    }
  }
}
