.button--primary {
  outline: none;
  border: none;
  background-color: $color-primary;
  font-weight: bolder;
  color: $color-white;
  padding: 0.4em 0.8em !important;
  border-radius: 0.6em;
  &:hover {
    background-color: $color-primary;
    transform: translateY(-1px) scale(1.01);
  }
  &:disabled{
    background-color: $color-primary;
    transform: translateY(-1px) scale(1.01);
  }
}
